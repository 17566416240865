.group {
  margin: 10px;
  padding: 5px;
  border-left: 2px solid lightgray;
}

.current-value {
  font-size: smaller;
  padding-left: 5px;
}

.current-value__hovering {
  background-color: lightgray;
  color: black;
  border: 1px solid dimgray;
  padding: 5px;
  z-index: 1000;
  position: absolute;
  transform: translateY(-100%);
}
