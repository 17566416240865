@import '../delphinus-style';

a {
  text-decoration: underline;
}

.home-title {
  b {
    font-size: x-large;
    margin-left: 2px;
  }
}

.app {
  justify-content: center;
  overflow-y: auto;

  &__content {
    width: min(1600px, 95%);
    /*height: calc(100vh - 50px); !* accounts for  navbar + padding in top and bottom *!*/
    position: relative;
    margin: 10px auto; /* a bit of room in top and bottom + centering for cross-browser compatibility */
  }
}
