@import '../common';

.notification--neutral {
  @extend .theme--background-secondary;
}

.soft-shadow {
  box-shadow: 1px 1px 7px darkgray;
}

.notification-container {
  position: relative;

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(40%, -40%);
  }
}
