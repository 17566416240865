@import '../delphinus-style';

h1 {
  font-size: xx-large;
  margin-bottom: 15px;
}

h2 {
  font-size: x-large;
}

button {
  @include set-colors('theme');
  @extend .button;
  @extend .button--small;
}

input[type='range'] {
  accent-color: color('theme');
}

input[type='text'],
input[type='password'] {
  display: block;
  border-radius: 0;
  border-width: 1px;
  padding: 5px;
}

textarea {
  border-radius: 0;
  min-height: 15px;
  max-height: 500px;
  resize: vertical;
  padding: 5px;
}

.height-400px {
  height: 400px;
}

.content-pane {
  @extend .theme--background-secondary;
}

.padded {
  padding: 20px;
}

.small-vert-margin {
  margin: 5px 0;
}

.with-margin {
  margin: 30px;
}

.flex-container--vert {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-container--horiz {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.flex-container__box--small {
  flex-grow: 0;
}

.flex-container__box {
  flex-grow: 1;
  min-width: 350px;
}

.flex-container__box--big {
  flex-grow: 2;
  min-width: 350px;
}

.flex-container__box--equal-size {
  flex: 1 1 0;
}

.size_40percent {
  width: 40%;
}

// TODO: should be elsewhere
.options-group:not(:first-child) {
  margin-top: 30px;
}
